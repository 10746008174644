// src/App.js
import './App.css';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from './pages/auth/login';
import AllHealthCard from './pages/healthcard';
import { useAuth } from './components/utils/loggedin';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Profile from './pages/profile';
import VerifyOTP from './pages/users/verifyotp';
import Users from './pages/users';
import VerifySalesRepOTP from './pages/auth/verifiyotp';
import Layout from './components/layout';
import HealthCard from './pages/healthcard/carddetails';
import Dashboard from './pages/home';
import AllotCard from './pages/healthcard/allotcard';
import Transaction from './pages/transaction';

// Define ProtectedRoute component to wrap protected routes
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>; // Render a loading spinner or message while authentication is being checked
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />; // Redirect unauthenticated users to the login page
  }

  return <Layout>{children}</Layout>; // Wrap protected content with the Layout component
};

const App = () => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>; // Render loading while authentication is being checked
  }

  return (
    <>
      <Routes>
        {/* Authenticated Routes */}
        <Route path="/" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="/login" element={isAuthenticated ? <Navigate to="/" /> : <Login />} />
        <Route path="/verifyotp" element={<VerifySalesRepOTP />} />
        <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
        <Route path="/healthcard" element={<ProtectedRoute><AllHealthCard /></ProtectedRoute>} />
        <Route path="/healthcard/view" element={<ProtectedRoute><HealthCard /></ProtectedRoute>} />
        <Route path="/patients" element={<ProtectedRoute><Users /></ProtectedRoute>} />
        <Route path="/user/verifyotp" element={<ProtectedRoute><VerifyOTP /></ProtectedRoute>} />
        <Route path='/healthcard/activate' element={<ProtectedRoute><AllotCard /></ProtectedRoute>} />
        <Route path='/salesrep/transactions' element={<ProtectedRoute><Transaction /></ProtectedRoute>} />
        <Route path="*" element={<ProtectedRoute><h1 className='m-10 p-10'>Page not found 404</h1></ProtectedRoute>} />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
