import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../components/utils/loggedin';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function AddUser({ fetchCustomer }) {
    const { token } = useAuth();
    const navigate = useNavigate()
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isLocating, setIsLocating] = useState(false);
    const [locationError, setLocationError] = useState("");
    const [addUser, setAddUser] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        aadhaar_card_number: '',
        dob: '',
        gender: '',
        address: '',
        state: '',
        city: '',
        zip: '',
        panchayat: '',
        ward_no: '',
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setAddUser((prevUser) => ({ ...prevUser, [id]: value }));
    };

    const registerCustomer = async (e) => {
        e.preventDefault(); // Prevent form submission
        setLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/salesrep/register-user`,
                addUser,
                {
                    headers: {
                        "Signature": process.env.REACT_APP_API_SIGNATURE,
                        Authorization: `Bearer ${token}`,
                    }
                }
            );
            document.getElementById('closeCanvas').click();
            toast.success("Customer added successfully");
            window.location.reload();
            fetchCustomer();

        } catch (error) {
            if (error.response && error.response.status === 400) {
                // Assuming the API returns a 409 status for email/phone conflict
                toast.warning("Email or phone number already exists");
            } else {
                console.error('Error adding customer:', error);
                setError('Error adding customer');
                toast.error("Failed to add customer");
            }
        } finally {
            setLoading(false);
        }
    };




    // fetch location by using api 
    const fetchCurrentLocation = () => {
        setIsLocating(true);
        setLocationError("");

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;

                    fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`)
                        .then((response) => response.json())
                        .then((data) => {
                            const { address } = data;
                            setAddUser((prevData) => ({
                                ...prevData,
                                address: `${address.road || ""}, ${address.suburb || ""}`,
                                city: address.city || address.town || address.village || "",
                                state: address.state || "",
                                zip: address.postcode || "",
                            }));
                            setIsLocating(false);
                        })
                        .catch((error) => {
                            console.error("Error fetching location data:", error);
                            setLocationError("Failed to fetch address from location.");
                            setIsLocating(false);
                        });
                },
                (error) => {
                    console.error("Geolocation error:", error);
                    setLocationError("Unable to access your location.");
                    setIsLocating(false);
                }
            );
        } else {
            setLocationError("Geolocation is not supported by your browser.");
            setIsLocating(false);
        }
    };

    return (
        <div
            className="offcanvas offcanvas-end w-offcanvas"
            tabIndex={-1}
            id="addUser"
            aria-labelledby="offcanvasEndLabel"
        >
            <div className="offcanvas-header">
                <h5 id="offcanvasEndLabel" className="offcanvas-title">
                    Add Patient Details
                </h5>
                <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                />
            </div>
            <form className="offcanvas-body" onSubmit={(e) => registerCustomer(e)}>
                {/* Customer Info Section */}
                <h6 className="section-heading">Patient Info</h6>
                <div className="row g-6">
                    {/* First Name */}
                    <div className="col-md-6">
                        <div className="form-floating form-floating-outline">
                            <input
                                type="text"
                                id="first_name"
                                className="form-control"
                                placeholder="John"
                                value={addUser?.first_name}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="first_name">First Name</label>
                        </div>
                    </div>
                    {/* Last Name */}
                    <div className="col-md-6">
                        <div className="form-floating form-floating-outline">
                            <input
                                type="text"
                                id="last_name"
                                className="form-control"
                                placeholder="Doe"
                                value={addUser?.last_name}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="last_name">Last Name</label>
                        </div>
                    </div>
                    {/* Email  */}
                    <div className="col-md-6">
                        <div className="form-floating form-floating-outline">
                            <input
                                type="email"
                                id="email"
                                className="form-control"
                                placeholder="john.doe@example.com"
                                value={addUser?.email}
                                onChange={handleChange}
                            />
                            <label htmlFor="email">Email</label>
                        </div>
                    </div>
                    {/* Phone Number */}
                    <div className="col-md-6">
                        <div className="form-floating form-floating-outline">
                            <input
                                type="text"
                                id="phone"
                                className="form-control phone-mask"
                                placeholder="+91"
                                value={addUser?.phone}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="phone">Phone No</label>
                        </div>
                    </div>
                    {/* aadhaar_card_number Card Number */}
                    <div className="col-md-6">
                        <div className="form-floating form-floating-outline">
                            <input
                                type="text"
                                id="aadhaar_card_number"
                                className="form-control"
                                placeholder="1234-5678-9012"
                                value={addUser?.aadhaar_card_number}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="aadhaar_card_number">Aadhaar Card Number</label>
                        </div>
                    </div>
                    {/* dob */}
                    {/* Date of Birth (DOB) */}
                    <div className="col-md-6">
                        <div className="form-floating form-floating-outline">
                            <input
                                type="date"
                                id="dob"
                                className="form-control"
                                placeholder="Date of Birth"
                                value={addUser?.dob}
                                onChange={handleChange}
                                required
                                max={new Date().toISOString().split('T')[0]}  // Sets the maximum selectable date to today
                            />
                            <label htmlFor="dob">Date of Birth</label>
                        </div>
                    </div>


                    {/* Gender */}
                    <div className="col-md-6">
                        <div className="form-floating form-floating-outline">
                            <select id="gender" className="form-select" value={addUser?.gender} onChange={handleChange} required>
                                <option value="">Select</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                            </select>
                            <label htmlFor="gender">Gender</label>
                        </div>
                    </div>
                </div>

                <hr className="my-6 mx-n4" />

                {/* Address Section */}
                <h6 className="section-heading">Address</h6>
                <div className="row g-6">
                    {/* Address */}
                    <div className="col-md-6">
                        <div className="form-floating form-floating-outline">
                            <input
                                type="text"
                                id="address"
                                className="form-control"
                                placeholder="123 Main St"
                                value={addUser?.address}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="address">Address</label>
                        </div>
                    </div>
                    {/* State */}
                    <div className="col-md-6">
                        <div className="form-floating form-floating-outline">
                            <input
                                type="text"
                                id="state"
                                className="form-control"
                                placeholder="California"
                                value={addUser?.state}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="state">State</label>
                        </div>
                    </div>
                    {/* City */}
                    <div className="col-md-6">
                        <div className="form-floating form-floating-outline">
                            <input
                                type="text"
                                id="city"
                                className="form-control"
                                placeholder="Los Angeles"
                                value={addUser?.city}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="city">City</label>
                        </div>
                    </div>
                    {/* Zip Code */}
                    <div className="col-md-6">
                        <div className="form-floating form-floating-outline">
                            <input
                                type="text"
                                id="zip"
                                className="form-control"
                                placeholder="90001"
                                value={addUser?.zip}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="zip">Zip Code</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-floating form-floating-outline">
                            <input
                                type="text"
                                id="panchayat"
                                className="form-control"
                                placeholder="90001"
                                value={addUser?.panchayat}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="panchayat">Panchayat</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-floating form-floating-outline">
                            <input
                                type="text"
                                id="ward_no"
                                className="form-control"
                                placeholder="90001"
                                value={addUser?.ward_no}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="ward_no">Ward No.</label>
                        </div>
                    </div>
                    {/* location icon  */}
                    <div className="d-flex align-items-center gap-2 mb-5" onClick={fetchCurrentLocation}
                        aria-disabled={isLocating}>
                        <div className="avatar">
                            <div className="avatar-initial bg-label-primary rounded-3">
                                <i className="ri-focus-3-line ri-24px"></i>
                            </div>
                        </div>
                        {isLocating ? "Locating..." : "Use My Current Location"}
                    </div>
                </div>

                {/* Submit and Cancel Buttons */}
                <div className="pt-6">
                    <button type="submit" className="btn btn-primary me-4" disabled={loading}>
                        {loading ? 'Submitting...' : 'Submit'}
                    </button>
                    <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="offcanvas" aria-label="Close" id='closeCanvas'>
                        Cancel
                    </button>
                </div>
                {error && <p className="text-danger mt-2">{error}</p>}
            </form>
        </div>
    );
}
