import React from 'react';

export default function HealthCardDetails({ healthCard }) {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = String(date.getFullYear()).slice(-2); // Get the last two digits of the year

        return `${day}/${month}/${year}`;
    };
    return (
        <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Health Card Details</h5>
            </div>
            <div className="table-responsive text-nowrap">
                <table className="table">
                    <tbody className="table-border-bottom-0">
                        <tr>
                            <td>
                                <span className="fw-medium">Card Number</span>
                            </td>
                            <td>{healthCard?.card_details.card_number}</td>
                        </tr>
                        <tr>
                            <td>
                                <span className="fw-medium">Aadhaar Card Number</span>
                            </td>
                            <td>{healthCard?.card_details.aadhaar_card_numbers.length > 0 ? healthCard?.card_details.aadhaar_card_numbers.join(', ') : 'Not Available'}</td>
                        </tr>
                        <tr>
                            <td>
                                <span className="fw-medium">Type</span>
                            </td>
                            <td>{healthCard?.card_details.type}</td>
                        </tr>
                        <tr>
                            <td>
                                <span className="fw-medium">Validity</span>
                            </td>
                            <td>{healthCard?.card_details.validity ? formatDate(healthCard?.card_details.validity) : 'Not Available'}</td>
                        </tr>
                        <tr>
                            <td>
                                <span className="fw-medium">Activated</span>
                            </td>
                            <td>{healthCard?.card_details.activated ? 'Yes' : 'No'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}
