import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../components/utils/loggedin';
import validator from 'validator';
import { toast } from 'react-toastify';

export default function Login() {
    const [identifier, setIdentifier] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateInputs()) return;

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/salesrep/login`,
                { identifier, password },
                {
                    headers: {
                        "Signature": process.env.REACT_APP_API_SIGNATURE
                    }
                });
            const { salesrep, token } = response.data;
            if (response.status === 200) {
                login(token);
                toast.success(`Welcome ${salesrep.first_name}!`);
                navigate('/');
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'Error logging in');
        }
    };

    const validateInputs = () => {
        const newErrors = {};

        if (validator.isEmpty(identifier)) {
            newErrors.identifier = 'Phone/Username is required';
        }

        if (validator.isEmpty(password)) {
            newErrors.password = 'Password is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    return (
        <div className="authentication-wrapper authentication-cover">
            <a href="/" className="auth-cover-brand d-flex align-items-center gap-2">
                <span className="app-brand-logo demo">
                    <span style={{ color: "var(--bs-primary)" }}>
                        <img height={80} src="./img/logo.png" alt="" />
                    </span>
                </span>
                <span className="app-brand-text demo text-heading fw-semibold">
                    Bharat eClinics
                </span>
            </a>

            <div className="authentication-inner row m-0">
                <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center justify-content-center p-12 pb-2">
                    <img
                        src="./img/illustrations/auth-login-illustration-light.png"
                        className="auth-cover-illustration w-100"
                        alt="auth-illustration"
                        data-app-light-img="illustrations/auth-login-illustration-light.png"
                        data-app-dark-img="illustrations/auth-login-illustration-dark.html"
                    />
                    <img
                        src="./img/illustrations/auth-cover-login-mask-light.png"
                        className="authentication-image"
                        alt="mask"
                        data-app-light-img="illustrations/auth-cover-login-mask-light.png"
                        data-app-dark-img="illustrations/auth-cover-login-mask-dark.html"
                    />
                </div>

                <div className="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg position-relative py-sm-12 px-12 py-6">
                    <div className="w-px-400 mx-auto pt-12 mt-4 pt-lg-0">
                        <h4 className="mb-0">Welcome to Bharat eClinics! 👋</h4>
                        <p className="mb-4">
                            Please sign-in to your account
                        </p>
                        <form
                            id="formAuthentication"
                            className="mb-5"
                            onSubmit={handleSubmit}
                        >
                            <div className="form-floating form-floating-outline mb-5">
                                <input
                                    type="text"
                                    className={`form-control ${errors.identifier ? 'is-invalid' : ''}`}
                                    id="identifier"
                                    name="identifier"
                                    placeholder="Enter your phone or username"
                                    value={identifier}
                                    onChange={(e) => setIdentifier(e.target.value)}
                                    autoFocus
                                />
                                <label htmlFor="identifier">Phone or Username</label>
                                {errors.identifier && <div className="invalid-feedback">{errors.identifier}</div>}
                            </div>
                            <div className="mb-5">
                                <div className="form-password-toggle">
                                    <div className="input-group input-group-merge">
                                        <div className="form-floating form-floating-outline">
                                            <input
                                                type={"password"}
                                                id="password"
                                                className={`form-control ${errors.identifier ? 'is-invalid' : ''}`}
                                                name="password"
                                                placeholder="••••••••"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                aria-describedby="password"
                                            />
                                            <label htmlFor="password">Password</label>
                                            {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="mb-5 d-flex justify-content-between mt-5">
                                <div className="form-check mt-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="remember-me"
                                        checked={rememberMe}
                                        onChange={() => setRememberMe(!rememberMe)}
                                    />
                                    <label className="form-check-label" htmlFor="remember-me">
                                        Remember Me
                                    </label>
                                </div>
                                <a href="auth-forgot-password-cover.html" className="float-end mb-1 mt-2">
                                    <span>Forgot Password?</span>
                                </a>
                            </div> */}
                            <button className="btn btn-primary d-grid w-100">Sign in</button>
                            {errors.submit && <div className="mt-3 text-danger">{errors.submit}</div>}
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
}
