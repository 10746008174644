import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from '../../components/utils/loggedin';

export default function AddCard() {
    const [aadhaarCardNumbers, setAadhaarCardNumbers] = useState(['']);
    const [cardNumber, setCardNumber] = useState('');
    const { token } = useAuth();

    const handleAadhaarCardChange = (index, value) => {
        const updatedAadhaarCardNumbers = [...aadhaarCardNumbers];
        updatedAadhaarCardNumbers[index] = value;
        setAadhaarCardNumbers(updatedAadhaarCardNumbers);
    };

    const addAadhaarCardInput = () => {
        if (aadhaarCardNumbers.length < 3) {
            setAadhaarCardNumbers([...aadhaarCardNumbers, '']);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.patch(`${process.env.REACT_APP_API_URL}/healthcard/salesrep/activate`, {
                aadhaar_card_numbers: aadhaarCardNumbers,
                card_number: cardNumber,
            }, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    "Authorization": `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                toast.success('Smart Health Card activated successfully');
                document.getElementById('closeModel').click();
                // Reset the form fields
                setAadhaarCardNumbers(['']);
                setCardNumber('');
                window.location.reload()
            } else {
                toast.error('Failed to activate Smart Health Card');
            }
        } catch (error) {
            toast.error(`${error.response ? error.response.data.message : 'An error occurred'}`);
        }
    };




    return (
        <div
            className="modal fade"
            id="addNewCard"
            tabIndex={-1}
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered1 modal-simple modal-add-new-cc">
                <div className="modal-content">
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    />
                    <div className="modal-body p-0">
                        <div className="text-center mb-6">
                            <h4 className="mb-2">Add New Smart Health Card</h4>
                            <p>Add new card to avail benefits</p>
                        </div>
                        <form id="addNewCCForm" className="row g-5" onSubmit={handleSubmit}>
                            {aadhaarCardNumbers.map((aadhaarCardNumber, index) => (
                                <div className="col-12" key={index}>
                                    <div className="input-group input-group-merge">
                                        <div className="form-floating form-floating-outline">
                                            <input
                                                id={`aadhaarCardNumber${index}`}
                                                name={`aadhaarCardNumber${index}`}
                                                className="form-control credit-card-mask"
                                                type="text"
                                                placeholder="1356 3215 6548 7898"
                                                value={aadhaarCardNumber}
                                                onChange={(e) => handleAadhaarCardChange(index, e.target.value)}
                                                aria-describedby={`modalAddCard${index}`}
                                            />
                                            <label htmlFor={`aadhaarCardNumber${index}`}>Aadhaar {index + 1}</label>
                                        </div>
                                        <span
                                            className="input-group-text cursor-pointer p-1"
                                            id={`modalAddCard${index}`}
                                        >
                                            <span className="card-type" />
                                        </span>
                                    </div>
                                </div>
                            ))}
                            {aadhaarCardNumbers.length < 3 && (
                                <div className="col-12">
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary"
                                        onClick={addAadhaarCardInput}
                                    >
                                        Add More
                                    </button>
                                </div>
                            )}
                            <div className="col-12">
                                <div className="input-group input-group-merge">
                                    <div className="form-floating form-floating-outline">
                                        <input
                                            id="cardNumber"
                                            name="cardNumber"
                                            className="form-control credit-card-mask"
                                            type="text"
                                            placeholder="BH0000XXXX"
                                            value={cardNumber}
                                            onChange={(e) => setCardNumber(e.target.value)}
                                            aria-describedby="modalAddCard2"
                                        />
                                        <label htmlFor="cardNumber">Card Number</label>
                                    </div>
                                    <span
                                        className="input-group-text cursor-pointer p-1"
                                        id="modalAddCard2"
                                    >
                                        <span className="card-type" />
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 d-flex flex-wrap justify-content-center gap-4 row-gap-4">
                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                                <button
                                    type="reset"
                                    className="btn btn-outline-secondary btn-reset"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    id='closeModel'
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
