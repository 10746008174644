import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useAuth } from '../../components/utils/loggedin';

const CardData = () => {
    const { token } = useAuth();
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchStatisticsData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/salesrep/statistics`, {
                    headers: {
                        "Signature": process.env.REACT_APP_API_SIGNATURE,
                        "Authorization": `Bearer ${token}`
                    }
                });
                setData(response.data);
            } catch (error) {
                console.error('There was an error fetching the sales rep data!', error);
            }
        };

        fetchStatisticsData();
    }, [token]);

    function getPercentage(value, total) {
        return total ? ((value / total) * 100).toFixed(1) : 0;
    }

    const totalCards = data?.totalCards || 0;
    const chartsData = [
        {
            color: '#72e128', // Green color
            series: getPercentage(totalCards, totalCards),
            label: 'All Cards',
            tasks: totalCards,
        },
        {
            color: '#fdb528', // Yellow color
            series: getPercentage(data?.totalCardsDeactivated || 0, totalCards),
            label: 'Total Deactivated Cards',
            tasks: data?.totalCardsDeactivated || 0,
        },
        {
            color: '#ff4d49', // Red color
            series: getPercentage(data?.totalExpiredCards || 0, totalCards),
            label: 'Total Expired Cards',
            tasks: data?.totalExpiredCards || 0,
        },
        {
            color: '#4caf50', // Green color
            series: getPercentage(data?.totalCardsActivatedBySalesRep || 0, totalCards),
            label: 'Total Activated Cards by Sales Rep',
            tasks: data?.totalCardsActivatedBySalesRep || 0,
        },
    ];

    const getOptions = (color) => ({
        chart: {
            height: 70,
            type: 'radialBar',
            sparkline: {
                enabled: true,
            },
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    size: '50%',
                },
                dataLabels: {
                    name: {
                        show: false,
                    },
                    value: {
                        offsetY: 5,
                        fontSize: '15px',
                        fontWeight: '600',
                        color: color,
                        show: true,
                        formatter: function (val) {
                            return val + '%';
                        },
                    },
                },
                track: {
                    background: '#E0E0E0',
                    strokeWidth: '100%',
                },
                strokeWidth: '14',
            },
        },
        fill: {
            colors: [color],
        },
        stroke: {
            lineCap: 'round',
        },
        labels: ['Progress'],
    });

    return (
        <div className="col-12 col-md-6 col-lg-4"> {/* Adjusted columns for responsiveness */}
            <div className="card h-100">
                <div className="card-header d-flex align-items-center justify-content-between">
                    <h5 className="card-title m-0 me-2">Sales Representative Overview</h5>
                </div>
                <div className="card-body">
                    <ul className="p-0 m-0">
                        {chartsData.map((item, index) => (
                            <li className="d-flex flex-column flex-md-row align-items-center mb-4" key={index}> {/* Changed to flex-column on smaller screens */}
                                <div className="me-0 me-md-3 mb-3 mb-md-0"> {/* Adjusted margin for mobile */}
                                    <Chart
                                        options={getOptions(item.color)}
                                        series={[item.series]}
                                        type="radialBar"
                                        height={80}
                                        width={80}
                                    />
                                </div>
                                <div className="row w-100 align-items-center">
                                    <div className="col-12 col-md-9"> {/* Adjusted columns for mobile */}
                                        <div className="text-center text-md-start"> {/* Center text on smaller screens */}
                                            <h6 className="mb-2">{item.label}</h6>
                                            <p className="mb-0 small">{item.tasks} {item.label}</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>

    );
};

export default CardData;
