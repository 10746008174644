import React from 'react';

export default function HealthCardDetails({ healthCard, isLoading }) {
    if (isLoading) {
        return (
            <div className="card text-center">
                <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                    <div className="card-header flex-column flex-md-row border-bottom">
                        <div className="head-label text-center">
                            <h5 className="card-title mb-0">Patient Details</h5>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                    <span style={{ color: 'green' }}>Please wait...</span>
                </div>
            </div>
        );
    }

    if (!healthCard.user_details || healthCard.user_details.length === 0) {
        return (
            <div className="card text-center">
                <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                    <div className="card-header flex-column flex-md-row border-bottom">
                        <div className="head-label text-center">
                            <h5 className="card-title mb-0">Patient Details</h5>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                    <span>No patient details found</span>
                </div>
            </div>
        );
    }

    return (
        <div className="card">
            <div className='dataTables_wrapper dt-bootstrap5 no-footer'>
                <div className="card-header flex-column flex-md-row border-bottom">
                    <div className="head-label text-center">
                        <h5 className="card-title mb-0">Patient Details</h5>
                    </div>
                    <div className="dt-action-buttons text-end pt-3 pt-md-0">
                        {/* <div className="dt-buttons btn-group flex-wrap">
                            <button
                                data-bs-target="#addNewCard"
                                data-bs-toggle="modal"
                                className="btn btn-secondary create-new btn-label-primary waves-effect waves-light"
                                tabIndex={0}
                                aria-controls="DataTables_Table_0"
                                type="button"
                            >
                                <span>
                                    <i className="ri-add-line ri-16px me-sm-2" />{" "}
                                    <span className="d-none d-sm-inline-block">Activate Card</span>
                                </span>
                            </button>{" "}
                        </div> */}
                    </div>
                </div>
            </div>

            {/* User Details Section */}
            <div className="table-responsive text-nowrap">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Aadhaar Card</th>
                            <th>Address</th>
                            <th>City</th>
                            <th>State</th>
                        </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                        {healthCard.user_details.map((userDetail, index) => (
                            <tr key={index}>
                                <td>{userDetail?.user?.username ? userDetail?.user?.username : "BHPTXXXXXX"}</td>
                                <td>{`${userDetail?.user?.first_name} ${userDetail?.user?.last_name}`}</td>
                                <td>{userDetail?.user?.email}</td>
                                <td>{userDetail?.user_details?.aadhaar_card_number}</td>
                                <td>{userDetail?.user_details?.address}</td>
                                <td>{userDetail?.user_details?.city}</td>
                                <td>{userDetail?.user_details?.state}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
