import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AddUser from './adduser';
import { Helmet } from 'react-helmet';
import { useAuth } from '../../components/utils/loggedin';

export default function Users() {
    const [users, setUsers] = useState([]);
    const { token } = useAuth();
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [limit, setLimit] = useState(10);
    const [searchPatientId, setSearchPatientId] = useState("");
    const [searchAadhaar, setSearchAadhaar] = useState("");
    const [searchName, setSearchName] = useState("");

    const fetchCustomer = async (page = 1) => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/salesrep/users/getall`, {
                params: {
                    page,
                    limit,
                    username: searchPatientId,
                    name: searchName,
                },
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    "Authorization": `Bearer ${token}`
                }
            })
            // Log to see the structure
            setUsers(response.data.users); // Make sure this is correct
            setCurrentPage(page);
            setTotalPages(response.data.pagination.totalPages);

        } catch (error) {
            console.log(`Error: ${error.response ? error.response.data.message : 'An error occurred'}`);
        } finally {
            setLoading(false);
        }
    };


    const renderPagination = () => {
        const pageNumbers = [];
        const maxPageButtons = 5;
        let startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
        let endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

        if (endPage - startPage < maxPageButtons - 1) {
            startPage = Math.max(endPage - maxPageButtons + 1, 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(i)}>
                        {i}
                    </button>
                </li>
            );
        }

        const handlePageChange = (page) => {
            if (page < 1 || page > totalPages) return;
            fetchCustomer(page);
        };

        return (
            <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                        <i className="tf-icon ri-arrow-left-s-line ri-20px" />
                    </button>
                </li>
                {startPage > 1 && (
                    <>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(1)}>1</button>
                        </li>
                        <li className="page-item disabled"><span className="page-link">...</span></li>
                    </>
                )}
                {pageNumbers}
                {endPage < totalPages && (
                    <>
                        <li className="page-item disabled"><span className="page-link">...</span></li>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
                        </li>
                    </>
                )}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                        <i className="tf-icon ri-arrow-right-s-line ri-20px" />
                    </button>
                </li>
            </ul>
        );
    };

    useEffect(() => {
        fetchCustomer();
    }, [limit, searchAadhaar, searchName, searchPatientId]);

    return (
        <>
            <Helmet>
                <title>Patients SalesRep - Bharat eClinics</title>
            </Helmet>
            <div className="card">
                <div className="card-datatable table-responsive pt-0">
                    <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                        <div className="card-header flex-column flex-md-row border-bottom">
                            <div className="head-label text-center">
                                <h5 className="card-title mb-0">Patients</h5>
                            </div>
                            <div className="dt-action-buttons text-end pt-3 pt-md-0">
                                <div className="dt-buttons btn-group flex-wrap">
                                    <button
                                        data-bs-toggle="offcanvas"
                                        className="btn btn-secondary create-new btn-label-primary waves-effect waves-light"
                                        data-bs-target="#addUser"
                                        tabIndex={0}
                                        aria-controls="DataTables_Table_0"
                                        type="button"
                                    >
                                        <span>
                                            <i className="ri-add-line ri-16px me-sm-2" />
                                            <span className="d-none d-sm-inline-block">Add New Patient</span>
                                        </span>
                                    </button>
                                    <AddUser fetchCustomer={fetchCustomer} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 mt-5 mt-md-0">
                                <div className="dataTables_length" id="DataTables_Table_0_length">
                                    <label>
                                        Show{" "}
                                        <select
                                            value={limit}
                                            onChange={(e) => setLimit(e.target.value)}
                                            name="DataTables_Table_0_length"
                                            aria-controls="DataTables_Table_0"
                                            className="form-select form-select-sm"
                                        >
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={75}>75</option>
                                            <option value={100}>100</option>
                                        </select>{" "}
                                        entries
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Patient Id</th>
                                        <th>Name</th>
                                        <th>Aadhaar Number</th>
                                        <th>Panchayat</th>
                                        <th>Ward No</th>
                                        <th>Address</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td>
                                            <input
                                                type="text"
                                                placeholder="Search by Patient Id"
                                                value={searchPatientId}
                                                onChange={(e) => setSearchPatientId(e.target.value)}
                                                className="form-control form-control-sm"
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                placeholder="Search by Patient Name"
                                                value={searchName}
                                                onChange={(e) => setSearchName(e.target.value)}
                                                className="form-control form-control-sm"
                                            />
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    {loading ? (
                                        <tr>
                                            <td colSpan="7" className="text-center">Loading...</td>
                                        </tr>
                                    ) : users.length === 0 ? (
                                        <tr>
                                            <td colSpan="7" className="text-center">No patient data found</td>
                                        </tr>
                                    ) : (
                                        users.map((user, index) => (
                                            <tr key={index}>
                                                <td>{(currentPage - 1) * limit + (index + 1)}</td>
                                                <td>{user.username ? user.username : "BHPTXXXXXX"}</td>
                                                <td>{user?.first_name + " " + user?.last_name}</td>
                                                <td>{user?.user_detail?.aadhaar_card_number}</td>
                                                <td>{user?.user_detail?.panchayat ? user?.user_detail?.panchayat : 'Not Available'}</td>
                                                <td>{user?.user_detail?.ward_no ? user?.user_detail?.ward_no : 'Not Available'}</td>
                                                <td>{user?.user_detail?.address + " " + user?.user_detail?.city + " " + user?.user_detail?.state + " " + user?.user_detail?.zip}</td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>

                        <div className="row mt-3">
                            <nav aria-label="Page navigation">
                                {renderPagination()}
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
