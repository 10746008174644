import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../components/utils/loggedin';
import { Helmet } from 'react-helmet';
import ViewHealthCardDetails from './healthcard';
import Userdetails from './userdetails';

export default function HealthCard() {
    const [tab, setTab] = useState("healthCardDetails");
    const location = useLocation();
    const navigate = useNavigate();
    const [healthCardData, setHealthCardData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { token } = useAuth();

    useEffect(() => {
        const healthCardId = location.state?.healthCardId;
        if (!healthCardId) {
            navigate('/healthcard');
        } else {
            fetchHealthCardData(healthCardId);
        }
    }, [location.state, navigate, token]);

    const fetchHealthCardData = async (healthCardId) => {
        setLoading(true);
        setError(null);  // Clear previous errors
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/healthcard/getcard/${healthCardId}`, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    Authorization: `Bearer ${token}`,
                }
            });
            setHealthCardData(response.data);
        } catch (error) {
            console.error('Error fetching health card data:', error);
            setError('Error fetching health card data');
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = String(date.getFullYear()).slice(-2); // Get the last two digits of the year

        return `${day}/${month}/${year}`;
    };

    const handleTab = (tab) => {
        setTab(tab);
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <>
            <Helmet>
                <title>View HealthCard Details - Doctor BharateClinics</title>
            </Helmet>

            <div className="row gy-6 gy-md-0">
                <div className="col-xl-12 col-lg-10 col-md-8 order-0 order-md-1">
                    <div className="nav-align-top">
                        <ul className="nav nav-pills flex-column flex-md-row mb-6 row-gap-2">
                            <li className="nav-item">
                                <button
                                    onClick={() => handleTab("healthCardDetails")}
                                    className={`btn nav-link ${tab === 'healthCardDetails' ? 'active' : ''}`}>
                                    <i className="ri-group-line me-2" />
                                    Health Card Details
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    onClick={() => handleTab("userdetails")}
                                    className={`btn nav-link ${tab === 'userdetails' ? 'active' : ''}`}>
                                    <i className="ri-group-line me-2" />
                                    Patient Details
                                </button>
                            </li>
                        </ul>
                    </div>
                    {tab === 'userdetails' && <Userdetails healthCard={healthCardData} loading={loading} />}
                    {tab === 'healthCardDetails' && <ViewHealthCardDetails healthCard={healthCardData} />}
                </div>
            </div>
        </>
    );
}
