import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../components/utils/loggedin';
import { Link } from 'react-router-dom';
import CardData from './carddata';
import { Helmet } from 'react-helmet';

export default function Dashboard() {
  const { token } = useAuth();
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchStasticsData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/salesrep/statistics`, {
          headers: {
            "Signature": process.env.REACT_APP_API_SIGNATURE,
            "Authorization": `Bearer ${token}`
          }
        });
        setData(response.data)
      } catch (error) {
        console.error('There was an error fetching the admin data!', error);
      }
    };

    fetchStasticsData();
  }, []);
  return (
    <>
      <Helmet>
        <title>Dashboard SalesRep - Bharat eClinics</title>
      </Helmet>

      <div className="row g-6">
        <div className="col-sm-6 col-lg-3">
          <div className="card card-border-shadow-primary h-100">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2">
                <div className="avatar me-4">
                  <span className="avatar-initial rounded-3 bg-label-primary">
                    <i className="menu-icon tf-icons ri-group-line"></i>

                  </span>
                </div>
                <h4 className="mb-0">{data?.totalCards}</h4>
              </div>
              <h6 className="mb-0 fw-normal">All  Cards</h6>
              <p className="mb-0">
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className="card card-border-shadow-warning h-100">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2">
                <div className="avatar me-4">
                  <span className="avatar-initial rounded-3 bg-label-warning">
                    <i class="ri-id-card-line"></i>

                  </span>
                </div>
                <h4 className="mb-0">{data?.totalCardsDeactivated}</h4>
              </div>
              <h6 className="mb-0 fw-normal">Total Deactivated Card </h6>
              <p className="mb-0">
                {/* <span className="me-1 fw-medium">-8.7%</span>
                <small className="text-muted">than last week</small> */}
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className="card card-border-shadow-danger h-100">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2">
                <div className="avatar me-4">
                  <span className="avatar-initial rounded-3 bg-label-danger">
                    <i className="menu-icon tf-icons ri-close-circle-line"></i>

                  </span>
                </div>
                <h4 className="mb-0">{data?.totalExpiredCards}</h4>
              </div>
              <h6 className="mb-0 fw-normal">Total Expired Cards</h6>
              <p className="mb-0">
                {/* <span className="me-1 fw-medium">+4.3%</span>
                <small className="text-muted">than last week</small> */}
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className="card card-border-shadow-success h-100">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2">
                <div className="avatar me-4">
                  <span className="avatar-initial rounded-3 bg-label-success">
                    <i className="menu-icon tf-icons ri-checkbox-circle-line"></i>

                  </span>
                </div>
                <h4 className="mb-0">{data?.totalCardsActivatedBySalesRep}</h4>
              </div>
              <h6 className="mb-0 fw-normal">Total Activated Card By Salesrep</h6>
              <p className="mb-0">
                {/* <span className="me-1 fw-medium">-2.5%</span>
                <small className="text-muted">than last week</small> */}
              </p>
            </div>
          </div>
        </div>




        {/*/ Card Border Shadow */}
        {/* chart part start's here  */}
        <CardData data={{ totalCardsActivatedBySalesRep: data?.totalCardsActivatedBySalesRep, totalCardsDeactivated: data?.totalCardsDeactivated, totalExpiredCards: data?.totalExpiredCards }} />


      </div>
      {/* <ShipmentStatisticsChart /> */}

    </>

  )
}
