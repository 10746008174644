import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../../components/utils/loggedin';

export default function AllotCard() {
    const location = useLocation();
    const navigate = useNavigate();
    const [healthCardData, setHealthCardData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeStep, setActiveStep] = useState(1);
    const [aadhaarCardNumbers, setAadhaarCardNumbers] = useState(['']);
    const [paymentMode, setPaymentMode] = useState('');
    const [userDetails, setUserDetails] = useState(null);
    const [cardDetails, setCardDetails] = useState(null);
    const { token } = useAuth();

    useEffect(() => {
        const healthCardId = location.state?.card_id;
        if (!healthCardId) {
            navigate('/healthcard');
        } else {
            fetchHealthCardData(healthCardId);
        }
    }, [location.state, navigate, token]);

    const fetchHealthCardData = async (healthCardId) => {
        setLoading(true);
        setError(null); // Clear previous errors
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/healthcard/getcard/${healthCardId}`, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    Authorization: `Bearer ${token}`,
                }
            });
            // Log the response for debugging
            setHealthCardData(response.data.card_details); // Set card_details directly
        } catch (error) {
            console.error('Error fetching health card data:', error);
            setError('Error fetching health card data');
        } finally {
            setLoading(false);
        }
    };

    const activateCard = async (payment_mode) => {
        try {
            const response = await axios.patch(`${process.env.REACT_APP_API_URL}/healthcard/salesrep/activate`, {
                aadhaar_card_numbers: aadhaarCardNumbers,
                card_number: healthCardData.card_number,
                payment_mode
            }, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    "Authorization": `Bearer ${token}`
                }
            });
            // Log the API response for debugging
            setUserDetails(response.data.user_details); // Store user details
            setCardDetails(response.data.card_details); // Store card details
            await handlePayment(response.data.smartHealthCard.id)
            setActiveStep(3);
        } catch (error) {
            console.error('Error validating Aadhaar card numbers:', error);
            return false;
        }
    };

    const validateAadhaarCards = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/healthcard/salesrep/validate`, {
                aadhaar_card_numbers: aadhaarCardNumbers,
                card_number: healthCardData.card_number

            }, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    "Authorization": `Bearer ${token}`,
                }
            });
            return true;
        } catch (error) {
            console.error('Validation Error:', error);
            toast.error(error.response.data.message);
            return false;
        }
    };



    const handleAadhaarCardChange = (index, value) => {
        const newAadhaarCardNumbers = [...aadhaarCardNumbers];
        newAadhaarCardNumbers[index] = value;
        setAadhaarCardNumbers(newAadhaarCardNumbers);
    };

    const handleAddAadhaarCard = () => {
        setAadhaarCardNumbers([...aadhaarCardNumbers, '']);
    };

    const handleRemoveAadhaarCard = (index) => {
        setAadhaarCardNumbers(aadhaarCardNumbers.filter((_, i) => i !== index));
    };

    const handlePaymentModeChange = (e) => {
        setPaymentMode(e.target.value);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year
        return `${day}/${month}/${year}`;
    };

    const currentDate = new Date();
    const nextYearDate = new Date(currentDate.setFullYear(currentDate.getFullYear() + 1));
    const validityDate = nextYearDate.toLocaleDateString(); // Format the date as needed

    const handleNext = async () => {
        if (activeStep === 1) {
            if (aadhaarCardNumbers.length === 0) {
                toast.error("Please add Aadhaar Card Number")
            } else {
                const validate = await validateAadhaarCards();
                if (validate) {

                    setActiveStep(2);
                }


            }
        } else if (activeStep === 2) {
            // Check if payment mode is selected
            if (!paymentMode) {
                alert('Please select a payment mode.');
                return;
            }
            // Move to Summary step
            setActiveStep(3);
        } else if (activeStep === 3) {
            // Implement final submit logic here if needed
            // Navigate to a different page or show a success message
            toast.success('Form submitted successfully!');
            // navigate('/some-other-page'); // Change this to your desired route
        }
    };

    const handlePaymentSubmit = async () => {
        if (paymentMode === "cash") {
            await activateCard("cash");
        } else if (paymentMode === "online") {
            await activateCard("online");
        }
    }

    const handlePayment = async (payment_type_id) => {

        // Load Razorpay script
        const loadRazorpay = () => {
            return new Promise((resolve) => {
                if (window.Razorpay) {
                    resolve(true);
                } else {
                    const script = document.createElement('script');
                    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
                    script.onload = () => resolve(true);
                    script.onerror = () => resolve(false);
                    document.body.appendChild(script);
                }
            });
        };

        const res = await loadRazorpay();

        if (!res) {
            toast.error('Razorpay failed to load. Are you online?');
            return;
        }

        try {
            // Replace with your static data or API call
            const orderUrl = `${process.env.REACT_APP_API_URL}/payments/create-order/salesrep`;
            const response = await axios.post(orderUrl, {
                amount: 499,
                currency: 'INR',
                payment_type: 'card',
                payment_type_id
            }, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    "Authorization": `Bearer ${token}`,
                }
            });

            const { id, currency, amount: orderAmount } = response.data;
            const options = {
                key: process.env.RAZORPAY_KEY_ID,
                amount: orderAmount,
                currency,
                name: 'Bharat eClinics',
                description: 'Bharat eClinics Appointment Booking',
                order_id: id,
                handler: async (response) => {
                    const verifyUrl = `${process.env.REACT_APP_API_URL}/payments/verify-payment`;
                    const { data } = await axios.post(verifyUrl, response);
                    toast.success('Payment Successful');
                    await activateCard();
                },
                prefill: {
                    name: 'John Doe',
                    email: 'john.doe@example.com',
                    contact: '9999999999',
                },
                notes: {
                    address: 'Razorpay Corporate Office',
                },
                theme: {
                    color: '#3399cc',
                },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.on('payment.failed', (response) => {
                console.error('Payment Failed:', response);
                toast.error('Payment failed. Please try again.');
            });

            paymentObject.open();
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred while processing the payment.');
        }
    };



    return (
        <>
            {/* Property Listing Wizard */}
            <div id="wizard-property-listing" className="bs-stepper vertical mt-2 linear">
                <div className="bs-stepper-header gap-lg-3 border-end">
                    {/* Card Details Header with Underline */}
                    <div className="text-lg-start mb-2">
                        <div className="head-label">
                            <h5 className="card-title mb-0">Card Details</h5>
                        </div>
                        <div className="border-bottom mt-1 mb-3"></div>
                    </div>

                    {/* Card Information Section */}
                    <div className="card-details">
                        <div className="row">
                            {/* Card Number */}
                            <div className="col-md-6 mb-2">
                                <p className="mb-0"><strong>Card Number:</strong></p>
                                <p>{healthCardData.card_number}</p>
                            </div>
                            {/* Type */}
                            <div className="col-md-6 mb-2">
                                <p className="mb-0"><strong>Type:</strong></p>
                                <p>{healthCardData.type}</p>
                            </div>
                            {/* Admin Type */}
                            <div className="col-md-6 mb-2">
                                <p className="mb-0"><strong>Admin Type:</strong></p>
                                <p>{healthCardData.admin_type}</p>
                            </div>
                            {/* Validity */}
                            <div className="col-md-6 mb-2">
                                <p className="mb-0"><strong>Validity:</strong></p>
                                <p>{formatDate(validityDate) || 'N/A'}</p>
                            </div>
                            {/* Status */}
                            <div className="col-md-6 mb-2">
                                <p className="mb-0"><strong>Status:</strong></p>
                                <p>{healthCardData.activated ? 'Activated' : 'Not Activated'}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bs-stepper-content">
                    <form id="wizard-property-listing-form" onSubmit={(e) => e.preventDefault()}>
                        {/* Steps Navigation */}
                        <div className="row g-5 mb-3">
                            <div className="col-12">
                                <div className="row g-5">
                                    <div className="col-md mb-md-0">
                                        <div className={`form-check custom-option custom-option-icon ${activeStep === 1 && "checked"}`}>
                                            <label className="form-check-label custom-option-content" htmlFor="customRadioAadhaar">
                                                <span className="custom-option-body">
                                                    <i className="ri-home-6-line ri-28px" />
                                                    <span className="custom-option-title mb-2">Aadhaar Card Numbers</span>
                                                </span>

                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md mb-3">
                                        <div className={`form-check custom-option custom-option-icon ${activeStep === 2 && "checked"}`}>
                                            <label className="form-check-label custom-option-content" htmlFor="customRadioPaymentMode">
                                                <span className="custom-option-body">
                                                    <i className="ri-wallet-line ri-28px" />
                                                    <span className="custom-option-title mb-2">Payment Mode</span>
                                                </span>

                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md mb-md-0">
                                        <div className={`form-check custom-option custom-option-icon ${activeStep === 3 && "checked"}`}>
                                            <label className="form-check-label custom-option-content" htmlFor="customRadioSummary">
                                                <span className="custom-option-body">
                                                    <i className="ri-file-text-line ri-28px" />
                                                    <span className="custom-option-title mb-2">Summary</span>
                                                </span>

                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Aadhaar Card Numbers Inputs */}
                        <div id="step-content" className={`content ${activeStep === 1 ? 'active' : ''}`}>
                            {activeStep === 1 && (
                                <div className="row g-5 ">
                                    <div className="col-12 mt-3">
                                        <div className="aadhaar-card-numbers">
                                            {aadhaarCardNumbers.map((number, index) => (
                                                <div key={index} className="d-flex align-items-center mb-2">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={number}
                                                        onChange={(e) => handleAadhaarCardChange(index, e.target.value)}
                                                        placeholder={`Aadhaar Card Number ${index + 1}`}
                                                    />
                                                    {aadhaarCardNumbers.length > 1 && (
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger ms-2"
                                                            onClick={() => handleRemoveAadhaarCard(index)}
                                                        >
                                                            Remove
                                                        </button>
                                                    )}
                                                </div>
                                            ))}
                                            {aadhaarCardNumbers.length < 3 && (
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={handleAddAadhaarCard}
                                                >
                                                    Add Another Aadhaar Card
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Payment Mode Inputs */}
                        <div id="step-content" className={`content ${activeStep === 2 ? 'active' : ''}`}>
                            {activeStep === 2 && (
                                <div className="row g-5">
                                    <div className="col-12 mt-3">
                                        <div className="form-check mt-2">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="paymentMode"
                                                id="paymentModeDebit"
                                                value="cash"
                                                checked={paymentMode === 'cash'}
                                                onChange={handlePaymentModeChange}
                                            />
                                            <label className="form-check-label" htmlFor="paymentModeDebit">
                                                Pay Cash
                                            </label>
                                        </div>
                                        <div className="form-check mt-2">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="paymentMode"
                                                id="paymentModeUPI"
                                                value="online"
                                                checked={paymentMode === 'online'}
                                                onChange={handlePaymentModeChange}
                                            />
                                            <label className="form-check-label" htmlFor="paymentModeUPI">
                                                Pay Online
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Summary Details */}
                        <div id="step-content" className={`content ${activeStep === 3 ? 'active' : ''}`}>
                            {activeStep === 3 && (
                                <div className="row g-5">
                                    <div className="col-12 mt-3">
                                        <div className="summary-details">
                                            <h5>Summary</h5>
                                            <p><strong>Aadhaar Card Numbers:</strong> {aadhaarCardNumbers.join(', ')}</p>
                                            <p><strong>Payment Mode:</strong> {paymentMode || 'N/A'}</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Navigation Buttons */}
                        {
                            !(activeStep === 3) &&
                            <>
                                {
                                    activeStep === 1 ?
                                        <div className="col-12 d-flex justify-content-between mt-6">
                                            <button
                                                className="btn btn-primary btn-next waves-effect waves-light"
                                                onClick={handleNext}
                                            >
                                                <span className="align-middle d-sm-inline-block d-none me-sm-1">Next</span>
                                                <i className="ri-arrow-right-line ri-16px align-middle" />
                                            </button>
                                        </div> :
                                        <div className="col-12 d-flex justify-content-between mt-6">
                                            <button
                                                className="btn btn-primary btn-next waves-effect waves-light"
                                                onClick={handlePaymentSubmit}
                                            >
                                                <span className="align-middle d-sm-inline-block d-none me-sm-1">Submit</span>
                                            </button>
                                        </div>
                                }
                            </>
                        }
                    </form>
                </div>
            </div>
        </>
    );
}
