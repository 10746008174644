// src/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [salesRepData, setSalesRepData] = useState(null);
  const [loading, setLoading] = useState(true); // Added loading state
  const navigate = useNavigate();

  const fetchSalesRepData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/salesrep/getsalesrep`, {
        headers: {
          "Signature": process.env.REACT_APP_API_SIGNATURE,
          "Authorization": `Bearer ${token}`
        }
      });
      const data = response.data;
      setSalesRepData(data);
    } catch (error) {
      console.error('There was an error fetching the salesrep data!', error);
      logout();
      navigate('/login');
    }
  };

  useEffect(() => {
    const storedToken = Cookies.get('token');
    if (storedToken) {
      setToken(storedToken);
      setIsAuthenticated(true);
    }
    setLoading(false); // Set loading to false after checking for token
  }, []);

  useEffect(() => {
    if (token) {
      fetchSalesRepData();
    }
  }, [token]);

  const login = (newToken) => {
    Cookies.set('token', newToken, { expires: 7 });
    setIsAuthenticated(true);
    setToken(newToken);
  };

  const logout = () => {
    Cookies.remove('token');
    setIsAuthenticated(false);
    setToken(null);
    navigate('/login'); // Redirect to login on logout
  };

  // Function to check if the user is authenticated
  const checkIsAuthenticated = () => {
    const storedToken = Cookies.get('token');
    return !!storedToken;
  };

  if (loading) {
    return <div>Loading...</div>; // Display loading state while authentication is being checked
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, salesRepData, token, login, logout, fetchSalesRepData, checkIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
