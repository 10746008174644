import React, { useState, useEffect } from 'react'
import Sidebar from './sidebar';
import Navbar from './navbar';
import { useLocation } from 'react-router-dom';

export default function Layout({ children }) {
    const [toggleMenu, setToggleMenu] = useState(false);
    const location = useLocation();

    const handleToggle = () => {
        setToggleMenu(!toggleMenu);
    }

    const closeMenu = () => {
        if (toggleMenu) {
            setToggleMenu(false);
        }
    }

    useEffect(() => {
        // Close menu when location changes (i.e., when a link is clicked)
        closeMenu();
    }, [location]);

    useEffect(() => {
        // Add click event listener to the document
        document.addEventListener('click', handleDocumentClick);

        // Cleanup function to remove the event listener
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [toggleMenu]);

    const handleDocumentClick = (event) => {
        // Check if the click is outside the menu and navbar
        const sidebar = document.querySelector('.layout-menu');
        const navbar = document.querySelector('.layout-navbar');
        if (sidebar && navbar && !sidebar.contains(event.target) && !navbar.contains(event.target)) {
            closeMenu();
        }
    }

    return (
        <div className={`light-style layout-navbar-fixed layout-compact layout-menu-100vh layout-menu-${toggleMenu ? 'expanded' : 'fixed'}`} dir="ltr" data-theme="theme-default data-assets-path=assets/" data-template="vertical-menu-template" data-style="light">
            <div className="layout-wrapper layout-content-navbar  ">
                <div className="layout-container">
                    {/* Menu */}
                    <Sidebar handleToggle={handleToggle} toggleMenu={toggleMenu} />
                    {/* / Menu */}
                    {/* Layout container */}
                    <div className="layout-page">
                        {/* Navbar */}
                        <Navbar handleToggle={handleToggle} />
                        {/* / Navbar */}
                        {/* Content wrapper */}
                        <div className="mt-10 content-wrapper">
                            {/* Content */}
                            <div className="container-xxl flex-grow-1 container-p-y">
                                {children}
                            </div>
                            {/* / Content */}
                            {/* Footer */}
                            <footer className="content-footer footer bg-footer-theme">
                                <div className="container-xxl">
                                    <div className="footer-container d-flex align-items-center justify-content-between py-4 flex-md-row flex-column">
                                        <div className="text-body text-centermb-2 mb-md-0">
                                            Copyright © 2024, Bharat eClinics
                                        </div>
                                    </div>
                                </div>
                            </footer>
                            {/* / Footer */}
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                    {/* / Layout page */}
                </div>
                {/* Overlay */}
                <div className="layout-overlay layout-menu-toggle" />
                {/* Drag Target Area To SlideIn Menu On Small Screens */}
                <div className="drag-target" />
            </div>
        </div>
    )
}