import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../../components/utils/loggedin";
import { useState, useEffect } from "react";
import Cropper from 'react-easy-crop';
import { getCroppedImg } from "./cropimg";

export default function AboutSalesRep({ salesRepData }) {
    const { token, fetchSalesRepData } = useAuth();
    const [isLocating, setIsLocating] = useState(false);
    const [locationError, setLocationError] = useState("");
    const [file, setFile] = useState(null);
    const [imageSrc, setImageSrc] = useState(null); // To display the image in the cropper
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    // Initialize form data state with the given salesRepData
    const [formData, setFormData] = useState({
        first_name: salesRepData.first_name || '',
        last_name: salesRepData.last_name || '',
        phone: salesRepData.phone || '',
        email: salesRepData.email || '',
        address: salesRepData.address || '',
        city: salesRepData.city || '',
        state: salesRepData.state || '',
        zip: salesRepData.zip || '',
        profile_img: salesRepData.profile_img || null
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
        if (name === 'city' && value.length > 2) {
            fetchlocationbycity(value);
        }
    };


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFile(file);
        const reader = new FileReader();
        reader.onload = () => setImageSrc(reader.result);
        reader.readAsDataURL(file);
    };

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let croppedImage = null;
            if (imageSrc) {
                croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels); // Get the cropped image blob
            }

            const form = new FormData();
            form.append('first_name', formData.first_name);
            form.append('last_name', formData.last_name);
            form.append('phone', formData.phone);
            form.append('email', formData.email);
            form.append('address', formData.address);
            form.append('city', formData.city);
            form.append('state', formData.state);
            form.append('zip', formData.zip);

            // Append either the new cropped image or the existing image URL
            if (croppedImage) {
                form.append('profile_img', croppedImage, 'croppedImage.jpg');
            } else if (formData.profile_img) {
                // Append the existing profile image URL if no new image is uploaded
                form.append('profile_img', formData.profile_img);
            }

            const response = await axios.put(`${process.env.REACT_APP_API_URL}/salesrep/updatesalesrep/${salesRepData.id}`, form, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "multipart/form-data"
                }
            });

            const { message, salesRep } = response.data;

            if (response.status === 200) {
                toast.success(message);
                setFormData({
                    first_name: salesRep.first_name,
                    last_name: salesRep.last_name,
                    phone: salesRep.phone,
                    email: salesRep.email,
                    address: salesRep.address,
                    city: salesRep.city,
                    state: salesRep.state,
                    zip: salesRep.zip,
                    profile_img: salesRep.profile_img
                });
                fetchSalesRepData();
                document.getElementById('closeProfileCanvas').click();
            } else {
                toast.error('Failed to update profile');
            }
        } catch (error) {
            toast.error(`${error.response ? error.response.data.message : 'An error occurred'}`);
        }
    };


    const fetchlocationbycity = async (city) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/address/city/${city}`, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                }
            });
            if (response.data && response.data.locations && response.data.locations.length > 0) {
                const locationData = response.data.locations[0];
                setFormData(prevState => ({
                    ...prevState,
                    address: locationData.address || "",
                    state: locationData.state || "",
                    zip: locationData.pincode || "",
                }));
            } else {
                toast.warning("No valid location data found for the given city.");
            }

        } catch (error) {
            console.log(error);
        }
    }

    const fetchCurrentLocation = () => {
        setIsLocating(true);
        setLocationError("");

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;

                    fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`)
                        .then((response) => response.json())
                        .then((data) => {
                            const { address } = data;
                            setFormData((prevData) => ({
                                ...prevData,
                                address: `${address.road || ""}, ${address.suburb || ""}`,
                                city: address.city || address.town || address.village || "",
                                state: address.state || "",
                                zip: address.postcode || "",
                            }));
                            setIsLocating(false);
                        })
                        .catch((error) => {
                            console.error("Error fetching location data:", error);
                            setLocationError("Failed to fetch address from location.");
                            setIsLocating(false);
                        });
                },
                (error) => {
                    console.error("Geolocation error:", error);
                    setLocationError("Unable to access your location.");
                    setIsLocating(false);
                }
            );
        } else {
            setLocationError("Geolocation is not supported by your browser.");
            setIsLocating(false);
        }
    };

    return (
        <div className="card mb-6">
            <div className="card-body">
                <div className='d-flex justify-content-between'>
                    <div className="card-text text-uppercase text-muted small">
                        Profile
                    </div>
                    <button
                        className="btn btn-label-primary me-2"
                        data-bs-target="#offcanvasAddUser"
                        data-bs-toggle="offcanvas"
                    >
                        <i className="ri-pencil-line me-2"></i>
                        Edit
                    </button>
                </div>
                <ul className="list-unstyled my-3 py-1">
                    <li className="d-flex align-items-center mb-4">
                        <i className="ri-user-3-line ri-24px" />
                        <span className="fw-medium mx-2">Username:</span>{" "}
                        <span>{salesRepData.username}</span>
                    </li>
                    <li className="d-flex align-items-center mb-4">
                        <i className="ri-user-3-line ri-24px" />
                        <span className="fw-medium mx-2">Full Name:</span>{" "}
                        <span className="text-capitalize">{`${salesRepData.first_name} ${salesRepData.last_name}`}</span>
                    </li>
                    <li className="d-flex align-items-center mb-4">
                        <i className="ri-check-line ri-24px" />
                        <span className="fw-medium mx-2">Status:</span>{" "}
                        <span>{salesRepData.verified ? 'Verified' : 'Not Verified'}</span>
                    </li>
                    <li className="d-flex align-items-center mb-4">
                        <i className="ri-star-smile-line ri-24px" />
                        <span className="fw-medium mx-2">Role:</span>{" "}
                        <span className='text-uppercase'>Sales Representative</span>
                    </li>
                    <li className="d-flex align-items-center mb-4">
                        <i className="ri-flag-2-line ri-24px" />
                        <span className="fw-medium mx-2">Address:</span>{" "}
                        <span>{`${salesRepData.address},${salesRepData.city}, ${salesRepData.state}, ${salesRepData.zip}`}</span>
                    </li>
                </ul>

                <small className="card-text text-uppercase text-muted small">
                    Contacts
                </small>
                <ul className="list-unstyled my-3 py-1">
                    <li className="d-flex align-items-center mb-4">
                        <i className="ri-phone-line ri-24px" />
                        <span className="fw-medium mx-2">Phone:</span>{" "}
                        <span>{salesRepData.phone}</span>
                    </li>
                    <li className="d-flex align-items-center mb-4">
                        <i className="ri-mail-open-line ri-24px" />
                        <span className="fw-medium mx-2">Email:</span>{" "}
                        <span>{salesRepData.email}</span>
                    </li>
                </ul>
            </div>

            {/* Edit profile modal */}
            <div className="offcanvas offcanvas-end canvas-width editprofileofsalesrep" tabIndex={-1} id="offcanvasAddUser" aria-labelledby="offcanvasAddUserLabel">
                <div className="offcanvas-header border-bottom">
                    <h5 id="offcanvasAddUserLabel" className="offcanvas-title">Update SalesRep Profile</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                </div>
                <div className="offcanvas-body mx-0 flex-grow-0 h-100">
                    <form className="add-new-user pt-0" id="updateDoctorForm" onSubmit={handleSubmit}>
                        {/* First Name and Last Name */}
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                className="form-control"
                                id="update-user-firstname"
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleChange}
                            />
                            <label htmlFor="update-user-firstname">First Name</label>
                        </div>

                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                className="form-control"
                                id="update-user-last_name"
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleChange}
                            />
                            <label htmlFor="update-user-last_name">Last Name</label>
                        </div>

                        {/* Email */}
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="email"
                                className="form-control"
                                id="update-user-email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                            <label htmlFor="update-user-email">Email</label>
                        </div>

                        {/* Phone */}
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                className="form-control"
                                id="update-user-contact"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                            />
                            <label htmlFor="update-user-contact">Phone</label>
                        </div>

                        {/* Address */}
                        <div className="form-floating form-floating-outline mb-5">
                            <textarea
                                className="form-control editaddress"
                                id="update-user-about"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                            />
                            <label htmlFor="update-user-address">Address</label>
                        </div>

                        {/* City */}
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                className="form-control"
                                id="update-user-city"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                            />
                            <label htmlFor="update-user-city">City</label>
                        </div>

                        {/* State */}
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                className="form-control"
                                id="update-user-state"
                                name="state"
                                value={formData.state}
                                onChange={handleChange}
                            />
                            <label htmlFor="update-user-state">State</label>
                        </div>

                        {/* Zip */}
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                className="form-control"
                                id="update-user-zip"
                                name="zip"
                                value={formData.zip}
                                onChange={handleChange}
                            />
                            <label htmlFor="update-user-zip">Zip</label>
                        </div>

                        {/* location icon  */}
                        <div className="d-flex align-items-center gap-2 mb-5" onClick={fetchCurrentLocation}
                            aria-disabled={isLocating}>
                            <div className="avatar">
                                <div className="avatar-initial bg-label-primary rounded-3">
                                    <i className="ri-focus-3-line ri-24px"></i>
                                </div>
                            </div>
                            {isLocating ? "Locating..." : "Use My Current Location"}
                        </div>

                        {/* Profile Image */}

                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="file"
                                className="form-control"
                                id="update-user-profile-img"
                                name="profile_img"
                                onChange={handleFileChange}
                            />
                            <label htmlFor="update-user-profile-img">Profile Image</label>
                        </div>

                        {imageSrc && (
                            <div className="crop-container" style={{ position: 'relative', width: '100%', height: '400px' }}>
                                <Cropper
                                    image={imageSrc}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={1} // 1:1 aspect ratio for square images
                                    onCropChange={setCrop}
                                    onZoomChange={setZoom}
                                    onCropComplete={onCropComplete}
                                />
                            </div>
                        )}

                        {/* Ensure Save Changes button is visible and placed below the crop section */}


                        {/* Submit and Reset Buttons */}
                        <button type="submit" className="btn btn-success me-sm-3 me-1">Update</button>
                        <button type="reset" id="closeProfileCanvas" className="btn btn-outline-secondary" data-bs-dismiss="offcanvas">Cancel</button>
                    </form>
                </div>
            </div>
        </div>
    );
}
