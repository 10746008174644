import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../utils/loggedin';
import axios from 'axios';

export default function Navbar({ handleToggle }) {
    const navigate = useNavigate();
    const { logout, salesRepData } = useAuth();


    return (
        <nav
            className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
            id="layout-navbar"
        >
            <div className="layout-menu-toggle navbar-nav align-items-xl-center me-4 me-xl-0   d-xl-none ">
                <button
                    onClick={handleToggle}
                    className="btn nav-item nav-link px-0 me-xl-6"
                    href="javascript:void(0)"
                >
                    <i className="ri-menu-fill ri-22px" />
                </button>
            </div>
            <div
                className="navbar-nav-right d-flex align-items-center"
                id="navbar-collapse"
            >
                <ul className="navbar-nav flex-row align-items-center ms-auto">

                    {/* User */}
                    <li className="nav-item navbar-dropdown dropdown-user dropdown">
                        <a
                            className="nav-link dropdown-toggle hide-arrow"
                            href="javascript:void(0);"
                            data-bs-toggle="dropdown"
                        >
                            <div className="avatar avatar-online">
                                <img
                                    src={salesRepData?.profile_img ? salesRepData?.profile_img : "./img/avatars/1.png"}
                                    alt=""
                                    className="rounded-circle"
                                />
                            </div>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li>
                                <Link
                                    className="dropdown-item"
                                    to="/profile"
                                >
                                    <div className="d-flex">
                                        <div className="flex-shrink-0 me-2">
                                            <div className="avatar avatar-online">
                                                <img
                                                    src={salesRepData?.profile_img ? salesRepData?.profile_img : "./img/avatars/1.png"}
                                                    alt=""
                                                    className="rounded-circle"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <span className="fw-medium d-block small">
                                                {salesRepData?.username}
                                            </span>
                                            <small className="text-muted">Sales Rep</small>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <div className="dropdown-divider" />
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/profile">
                                    <i className="ri-user-3-line ri-22px me-3" />
                                    <span className="align-middle">My Profile</span>
                                </Link>
                            </li>
                            <li>
                                <div className="dropdown-divider" />
                            </li>
                            <li>
                                <div className="d-grid px-4 pt-2 pb-1">
                                    <button
                                        className="btn btn-sm btn-danger d-flex"
                                        onClick={() => {
                                            logout();
                                            navigate('/login');
                                        }}
                                    >
                                        <small className="align-middle">Logout</small>
                                        <i className="ri-logout-box-r-line ms-2 ri-16px" />
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </li>
                    {/*/ User */}
                </ul>
            </div>
            {/* Search Small Screens */}
            <div className="navbar-search-wrapper search-input-wrapper  d-none">
                <input
                    type="text"
                    className="form-control search-input container-xxl border-0"
                    placeholder="Search..."
                    aria-label="Search..."
                />
                <i className="ri-close-fill search-toggler cursor-pointer" />
            </div>
        </nav>
    )
}
