import { useState } from 'react';
import { useAuth } from '../../components/utils/loggedin';
import AboutSalesRep from './aboutsalesrep';
import Security from './security';
import { Helmet } from 'react-helmet';

export default function Profile() {
    const { salesRepData } = useAuth();
    const [tab, setTab] = useState('details');

    function handleTab(tab) {
        setTab(tab);
    }

    // Early return if salesRepData is not available
    if (!salesRepData) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Helmet>
                <title>Profile SalesRep - Bharat eClinics</title>
            </Helmet>

            {/* Header */}
            <div className="row">
                <div className="col-12">
                    <div className="card mb-6">
                        <div className="user-profile-header-banner">
                            <img
                                src="/img/pages/profile-banner.png"
                                alt="Banner image"
                                className="rounded-top"
                            />
                        </div>
                        <div className="user-profile-header d-flex flex-column flex-sm-row text-sm-start text-center mb-5">
                            <div className="flex-shrink-0 mt-n2 mx-sm-0 mx-auto">
                                <img
                                    src={salesRepData.profile_img ? salesRepData.profile_img : './img/avatars/1.png'} // Dynamic profile image
                                    alt="user image"
                                    className="d-block h-auto ms-0 ms-sm-5 rounded-4 user-profile-img"
                                />
                            </div>
                            <div className="flex-grow-1 mt-4 mt-sm-12">
                                <div className="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-5 flex-md-row flex-column gap-6">
                                    <div className="user-profile-info">
                                        <h4 className="mb-2 text-capitalize">{salesRepData.first_name} {salesRepData.last_name}</h4>
                                        <ul className="list-inline mb-0 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-4">
                                            <li className="list-inline-item">
                                                <i className="ri-admin-line me-2 ri-24px" />
                                                <span className="fw-medium">Sales Representative</span>
                                            </li>
                                            <li className="list-inline-item">
                                                <i className="ri-map-pin-line me-2 ri-24px" />
                                                <span className="fw-medium">{salesRepData.city || 'Unknown City'}</span> {/* Fallback for city */}
                                            </li>
                                            <li className="list-inline-item">
                                                <i className="ri-calendar-line me-2 ri-24px" />
                                                <span className="fw-medium">Joined {new Date(salesRepData.created_at).toLocaleString('default', { month: 'long' }) + ' ' + new Date(salesRepData.created_at).getFullYear()}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* Button for Verification Status */}
                                    <button className={`btn btn-${salesRepData.verified ? 'success' : 'outline-success'}`}>
                                        <i className="ri-user-follow-line ri-16px me-2" />
                                        {salesRepData.verified ? 'Verified' : 'Not Verified'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*/ Header */}

            {/* Tab Navigation */}
            <div className="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1">
                {/* User Tabs */}
                <div className="nav-align-top">
                    <ul className="nav nav-pills flex-column flex-md-row mb-6 row-gap-2">
                        <li className="nav-item">
                            <button
                                onClick={() => handleTab("details")}
                                className={`btn nav-link ${tab === "details" ? "active" : ""}`}
                            >
                                <i className="ri-group-line me-2" />
                                Profile
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                onClick={() => handleTab("security")}
                                className={`btn nav-link ${tab === "security" ? "active" : ""}`}
                            >
                                <i className="ri-lock-2-line me-2" />
                                Password
                            </button>
                        </li>
                    </ul>
                </div>
                {/*/ User Tabs */}

                {/* Conditional Rendering Based on Selected Tab */}
                {tab === "details" && (
                    <AboutSalesRep salesRepData={salesRepData} />
                )}
                {tab === "security" && <Security salesRepData={salesRepData} />}
            </div>
        </>
    );
}
