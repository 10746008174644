import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../components/utils/loggedin';
import AddCard from './addcard';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function AllHealthCard() {
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [limit, setLimit] = useState(10);
    const [searchCardNumber, setSearchCardNumber] = useState("");
    const [searchStatus, setSearchStatus] = useState("");
    const { token, salesRepData } = useAuth();
    const navigate = useNavigate();

    function ViewHealthCardDetails(healthCardId) {
        navigate('/healthcard/view', { state: { healthCardId } });
    }

    const fetchCards = async (page = 1) => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/healthcard/salesrep?page=${page}&limit=${limit}&card_number=${searchCardNumber}&status=${searchStatus}`, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    "Authorization": `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                setCards(response.data.data);
                setTotalPages(response.data.pagination.totalPages);
                setCurrentPage(page);
            } else {
                console.log('Failed to fetch Smart Health Cards');
            }
        } catch (error) {
            console.log(`Error: ${error.response ? error.response.data.message : 'An error occurred'}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCards();
    }, [limit, searchCardNumber, searchStatus]);

    const handlePageChange = (page) => {
        if (page < 1 || page > totalPages) return;
        fetchCards(page);
    };

    const renderPagination = () => {
        const pageNumbers = [];
        const maxPageButtons = 5;
        let startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
        let endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

        if (endPage - startPage < maxPageButtons - 1) {
            startPage = Math.max(endPage - maxPageButtons + 1, 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(i)}>
                        {i}
                    </button>
                </li>
            );
        }

        return (

            <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                        <i className="tf-icon ri-arrow-left-s-line ri-20px" />
                    </button>
                </li>
                {startPage > 1 && (
                    <>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(1)}>1</button>
                        </li>
                        <li className="page-item disabled"><span className="page-link">...</span></li>
                    </>
                )}
                {pageNumbers}
                {endPage < totalPages && (
                    <>
                        <li className="page-item disabled"><span className="page-link">...</span></li>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
                        </li>
                    </>
                )}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                        <i className="tf-icon ri-arrow-right-s-line ri-20px" />
                    </button>
                </li>
            </ul>
        );
    };

    function allotCard(card_id) {
        navigate("/healthcard/activate", { state: { card_id } })
    }

    return (
        <>
            <Helmet>
                <title>Health Card SalesRep - Bharat eClinics</title>
            </Helmet>
            <div className="card">
                <div className="card-datatable table-responsive pt-0 pb-0">
                    <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                        <div className="card-header flex-column flex-md-row border-bottom">
                            <div className="head-label text-center">
                                <h5 className="card-title mb-0">Smart Health Card</h5>
                            </div>
                            <div className="dt-action-buttons text-end pt-3 pt-md-0">
                                <div className="dt-buttons btn-group flex-wrap">
                                    {<button
                                        data-bs-target="#addNewCard"
                                        data-bs-toggle="modal"
                                        className="btn btn-secondary create-new btn-label-primary waves-effect waves-light"
                                        tabIndex={0}
                                        aria-controls="DataTables_Table_0"
                                        type="button"
                                    >
                                        <span>
                                            <i className="ri-add-line ri-16px me-sm-2" />{" "}
                                            <span className="d-none d-sm-inline-block">Activate Card</span>
                                        </span>
                                    </button>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 mt-5 mt-md-0">
                                <div className="dataTables_length" id="DataTables_Table_0_length">
                                    <label>
                                        Show{" "}
                                        <select
                                            value={limit}
                                            onChange={(e) => setLimit(e.target.value)}
                                            name="DataTables_Table_0_length"
                                            aria-controls="DataTables_Table_0"
                                            className="form-select form-select-sm"
                                        >

                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={75}>75</option>
                                            <option value={100}>100</option>
                                        </select>{" "}
                                        entries
                                    </label>
                                </div>
                            </div>
                        </div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Card Number</th>
                                    <th>Status</th>
                                    <th>Type</th>
                                    <th>Payment Mode</th>
                                    <th>Validity</th>
                                    <th>Generated On</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>
                                        <input
                                            type="text"
                                            placeholder="Search by Card Number"
                                            value={searchCardNumber}
                                            onChange={(e) => setSearchCardNumber(e.target.value)}
                                            className="form-control form-control-sm"
                                        />
                                    </td>
                                    <td className="dataTables_length" id="DataTables_Table_0_length">
                                        <label className="d-flex mb-0 align-items-center gap-2">
                                            <select
                                                name="DataTables_Table_0_status"
                                                aria-controls="DataTables_Table_0"
                                                className="form-select form-select-sm"
                                                value={searchStatus}
                                                onChange={(e) => setSearchStatus(e.target.value)}
                                            >
                                                <option value="">Select Status</option>
                                                <option value="true">Activated</option>
                                                <option value="false">Not Activated</option>
                                            </select>{" "}
                                        </label>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                {loading ? (
                                    <tr>
                                        <td colSpan="7">Loading...</td>
                                    </tr>
                                ) : (
                                    cards.map((card, index) => (
                                        <tr key={card.id}>
                                            <td>{(currentPage - 1) * limit + index + 1}</td>
                                            <td>{card.card_number}</td>
                                            <td>
                                                {card.activated ? (
                                                    <span className="badge bg-label-success rounded-pill">Activated</span>
                                                ) : (
                                                    <span className="badge bg-label-danger rounded-pill">Not Activated</span>
                                                )}
                                            </td>
                                            <td>{card.type}</td>
                                            <td>{card.payment_mode}</td>
                                            <td>
                                                {card.validity ? new Date(card.validity).toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Not Available'}
                                            </td>
                                            <td>{new Date(card.created_at).toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                                            <td>


                                                <button
                                                    type="button"
                                                    className="btn p-0 dropdown-toggle hide-arrow"
                                                    data-bs-toggle="dropdown"
                                                >
                                                    <i className="ri-more-2-line" />
                                                </button>
                                                <div className="dropdown-menu">
                                                    <button onClick={() => ViewHealthCardDetails(card.id)} className="dropdown-item waves-effect">
                                                        <i className="ri-eye-line me-2" />View
                                                    </button>
                                                    {
                                                        !card.activated &&
                                                        <button
                                                            className="dropdown-item waves-effect"
                                                            onClick={() => allotCard(card.id)}
                                                        >
                                                            <i className="ri-heart-add-line me-2" /> Activate Card
                                                        </button>

                                                    }

                                                </div>

                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                        <div className="row mt-3">
                            <nav aria-label="Page navigation">
                                {renderPagination()}
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <AddCard />
        </>
    );
}
