import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../components/utils/loggedin';

export default function Transaction() {
    const [txn, setTxn] = useState([]);
    const { token } = useAuth();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalItems, setTotalItems] = useState(0);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year
        return `${day}/${month}/${year}`;
    };

    const fetchTxn = async (page = 1) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/transaction/salesrep/history?page=${page}&limit=${limit}`, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    "Authorization": `Bearer ${token}`,
                }
            });

            // Set transaction data
            setTxn(response.data.transactions);
            // Update pagination information
            setCurrentPage(response.data.pagination.currentPage);
            setTotalPages(response.data.pagination.totalPages);
            setTotalItems(response.data.pagination.totalItems);
        } catch (error) {
            console.error("Error fetching transaction data:", error);
        }
    };

    const handlePageChange = (page) => {
        if (page < 1 || page > totalPages) return;
        fetchTxn(page);
    };

    const renderPagination = () => {
        if (totalItems <= limit) return null; // No need for pagination if total items are less than or equal to the limit

        const pageNumbers = [];
        const maxPageButtons = 5; // The number of visible page buttons
        let startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
        let endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

        if (endPage - startPage < maxPageButtons - 1) {
            startPage = Math.max(endPage - maxPageButtons + 1, 1);
        }

        // Generate page number buttons
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(i)}>
                        {i}
                    </button>
                </li>
            );
        }

        return (
            <ul className="pagination">
                {/* Previous button */}
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <i className="tf-icon ri-arrow-left-s-line ri-20px" />
                    </button>
                </li>

                {/* Display first page and ellipsis if necessary */}
                {startPage > 1 && (
                    <>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(1)}>
                                1
                            </button>
                        </li>
                        {startPage > 2 && (
                            <li className="page-item disabled">
                                <span className="page-link">...</span>
                            </li>
                        )}
                    </>
                )}

                {/* Page numbers */}
                {pageNumbers}

                {/* Display last page and ellipsis if necessary */}
                {endPage < totalPages && (
                    <>
                        {endPage < totalPages - 1 && (
                            <li className="page-item disabled">
                                <span className="page-link">...</span>
                            </li>
                        )}
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(totalPages)}>
                                {totalPages}
                            </button>
                        </li>
                    </>
                )}

                {/* Next button */}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <i className="tf-icon ri-arrow-right-s-line ri-20px" />
                    </button>
                </li>
            </ul>
        );
    };

    useEffect(() => {
        fetchTxn();
    }, [token, limit]);

    return (
        <div className="card">
            <h5 className="card-header flex-column flex-md-row border-bottom">
                Transactions
            </h5>
            <div className='card body'>
                <div className="card-datatable table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <td colSpan="7">
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <select
                                            className="form-select form-select-sm"
                                            value={limit}
                                            onChange={(e) => setLimit(Number(e.target.value))}
                                            style={{ width: '150px' }}
                                        >
                                            <option value={10}>10 per page</option>
                                            <option value={20}>20 per page</option>
                                            <option value={50}>50 per page</option>
                                            <option value={100}>100 per page</option>
                                        </select>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>Tnx ID</th>
                                <th>Payment Type</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Credited On</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(!txn || txn.length === 0) ? (
                                <tr>
                                    <td colSpan="5" className="text-center">No data found</td>
                                </tr>
                            ) : (
                                txn.map((transaction, index) => (
                                    <tr key={index}>
                                        <td>{transaction.razorpay_payment_id}</td>
                                        <td>{transaction.payment_type}</td>
                                        <td>₹ {transaction.amount}</td>
                                        <td>{transaction.status}</td>
                                        <td>{formatDate(transaction.updatedAt)}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                    <div className="row mt-3">
                        <nav aria-label="Page navigation">
                            {renderPagination()}
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
}
